import { Application } from "@hotwired/stimulus"
const application = Application.start()

import controllers from "./../controllers/*_controller.js"

controllers.forEach((controller) => {
  application.register(
      controller.name.replace('..--controllers--', ''),
      controller.module.default
  )
})