import { Turbo } from "@hotwired/turbo-rails"

export const MODAL = (function() {
  function httpError(data) {
    $('#bfom-modal').html(data.modal);
  }

  function initModal(data) {
    $('#bfom-modal').html(data.modal);
    $('#bfom-modal').modal('show');
    handleSubmit();
  }

  function handleSubmit() {
    $('#bfom-modal').find('form').off().on('submit', function (ev) {
      ev.preventDefault();

      $.ajax({
        url: $(this).attr('action'),
        method: $(this).attr('method'),
        data: $(this).serialize(),
        success: function (data) {
          if (data.redirect) {
            Turbo.visit(data.redirect);
          }
        },
        error: function (data) {
          initModal(data.responseJSON);
        }
      })
    })
  }

  return Object.freeze({
    init: function (ev) {
      ev.preventDefault();
      let url = $(ev.target).closest('a').attr('href');

      $.get(url, function (data) {
        initModal(data)
      });
    }
  })
})();