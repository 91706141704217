import './cookies_eu'
import './maps'
import './recipes'
import './restaurants'
import './stores'
import './wineries'
import { Turbo } from "@hotwired/turbo-rails"
import { MODAL } from "./bfom_modal";

$(document).on('click', '.link-img', function () {
  var link = $(this).attr('href');

  Turbo.visit(link);
});

$(document).on('turbo:load', function () {
  $('[data-role="tagsinput"]').tagsinput();
  $('img:not(.notlazy)').lazyload();

  setTimeout(function () {
    $('[data-flash="main"]').fadeOut();
  }, 2500)
});

$(document).on('click', 'a[data-modal="remote"]', function (ev) {
  MODAL.init(ev);
});