/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const ready = function() {
  initResourceMap();
  return initIndexMap();
};


var initResourceMap = function() {
  const lat = $('#resource-map').data('lat');
  const lng = $('#resource-map').data('lng');

  if (lat && lng) {
    const myCoords = new google.maps.LatLng(lat, lng);
    const mapOptions = {
      center: myCoords,
      zoom: 14
    };

    const element = document.getElementById('resource-map');
    if (element) {
      let marker;
      const map = new google.maps.Map(element, mapOptions);

      return marker = new google.maps.Marker({
        position: myCoords,
        map
      });
    }
  }
};

var initIndexMap = function() {
  const mapElement = $('#collection-map');

  if (mapElement) {
    const myCoords = new google.maps.LatLng(35.917973, 14.409943);
    const data = mapElement.data('markers');
    const mapOptions = {
      center: myCoords,
      zoom: 11
    };

    const element = document.getElementById('collection-map');
    if (element) {
      const map = new google.maps.Map(element, mapOptions);
      return $.each(data, function(i, el) {
        const position = new google.maps.LatLng(el.lat, el.lng);
        return new google.maps.Marker({
          position,
          map
        });
      });
    }
  }
};

$(document).on('turbo:load', ready);