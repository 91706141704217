// Entry point for the build script in your package.json
import './config/jquery'
import './config/bootstrap'
import './config/trix'
import 'dropzone/dist/dropzone'
import './vendor/tagsinput'
import './vendor/jquery.lazyload'
import './vendor/slick'
import Rails from "@rails/ujs"
import { Turbo } from "@hotwired/turbo-rails"
window.Rails = Rails
window.Turbo = Turbo

require("@rails/activestorage").start()
import "@rails/actiontext"

import './src/index'
import './packs/application'

Rails.start()
import "@hotwired/turbo-rails"
